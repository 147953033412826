import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import OrderedList from "./OrderedList";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "orderedlist"
    }}>{`OrderedList`}</h1>
    <Props of={OrderedList} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<OrderedList>\n  <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit</strong>\n  <em>Lorem ipsum dolor sit amet, consectetur adipiscing elit</em>\n  <a href=\"#\">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>\n</OrderedList>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      OrderedList,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <OrderedList mdxType="OrderedList">
    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit</strong>
    <em>Lorem ipsum dolor sit amet, consectetur adipiscing elit</em>
    <a href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
  </OrderedList>
    </Playground>
    <Playground __position={2} __code={'<OrderedList>\n  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>\n  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>\n  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>\n</OrderedList>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      OrderedList,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <OrderedList mdxType="OrderedList">
    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
  </OrderedList>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      